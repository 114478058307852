import idMaps from "../../mockData/idMaps";

function sortMeasures(measures: any) {
  let newMeasures: any = [];

  measures.forEach((i: any) => {
    let split_name = i.measure.split("_");
    let side = "";

    if (split_name.includes("L")) {
      side = "L";
    } else {
      side = "R";
    }
    if (i.measure === "F_lK-T" || i.measure === "B_lK-T") {
      let measure_item = {};
      measure_item["client_name"] = i.measure;
      measure_item["side"] = i.measure.includes("F") ? "F" : "B";
      measure_item["cir"] = i["cir. measures"];
      measure_item["height"] = i["height"];
      let mapped_values = idMaps.find(
        ({ client_id }: any) => client_id === i.measure
      );
      measure_item["mvp_id"] = mapped_values?.mvp_id;
      newMeasures.push(measure_item);
    } else if (i.measure === "HAl") {
      // case for css being case insensitive and there being
      // an HAl and Hal
      let measure_item = {};
      measure_item["client_name"] = i.measure;
      measure_item["side"] = i.measure.includes("F") ? "F" : "B";
      measure_item["cir"] = i["cir. measures"];
      measure_item["height"] = i["height"];
      let mapped_values = idMaps.find(
        ({ client_id }: any) => client_id === i.measure
      );
      measure_item["mvp_id"] = `_${mapped_values?.mvp_id}`;
      newMeasures.push(measure_item);
    } else {
      split_name.forEach((e: any) => {
        if (e === "L" || e === "R") {
          return;
        } else {
          let measure_item = {};
          measure_item["side"] = side;
          measure_item["client_id"] = e;
          let mapped_values = idMaps.find(
            ({ client_id }: any) => client_id === e
          );
          measure_item["mvp_id"] = mapped_values?.mvp_id;
          measure_item["value"] =
            mapped_values?.type === "circumference"
              ? i["cir. measures"]
              : i["height"];
          newMeasures.push(measure_item);
        }
      });
    }
  });
  return newMeasures;
}

export default sortMeasures;
