import idMaps from "../../mockData/idMaps";

const getHiddenIds = (measures: any) => {
  if (!measures) {
    return "";
  }
  let returnString = "";

  let idsForHiding = idMaps.filter((item: any) => {
    return !measures.some((item2: any) => {
      return item.mvp_id === item2.mvp_id;
    });
  });

  idsForHiding.forEach((i: any) => {
    if (i.mvp_id === "HAl") {
      returnString += `#_${i.mvp_id}, `;
    }
    returnString += `#${i.mvp_id}, `;
  });

  return returnString.slice(0, -2);
};

export default getHiddenIds;
