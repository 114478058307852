const header = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": `${process.env.REACT_APP_SERVER}`,
};

interface FetchJobDataProps {
  database:
    | "sigvaris-qa-db"
    | "sigvaris-prod"
    | "cartier-test"
    | "cartierprodwest";
  token: any;
}

export const fetchJobs = async ({ database, token }: FetchJobDataProps) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...header,
      Authorization: `Bearer ${token}`,
    },
  };

  const URL = `${process.env.REACT_APP_SERVER_URL}/jobs/${database}`;
  const res = await fetch(URL, requestOptions);
  const json = await res.json();
  return json;
};

export const fetchJob = async (id: any, database: string, token: any) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...header,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      database: database,
    }),
  };

  const URL = `${process.env.REACT_APP_SERVER_URL}/jobs/${id}`;
  const res = await fetch(URL, requestOptions);
  const json = await res.json();
  return json;
};

export const downloadFile = async (
  id: any,
  database: string,
  path: string,
  filename: string,
  token?: any
) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...header,
      //   Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      database: database,
      path: path,
      filename: filename,
    }),
  };

  const URL = `${process.env.REACT_APP_SERVER_URL}/jobs/${id}/download`;
  const res = await fetch(URL, requestOptions);
  return res;
};

export const getMeasurements = async (
  job_id: any,
  path: any,
  database: any
) => {
  const requestOptions = {
    method: "GET",
    headers: {
      ...header,
    },
  };

  const URL = `${process.env.REACT_APP_SERVER_URL}/jobs/download?job_id=${job_id}&path=${path}&filename=measurements.json&database=${database}`;

  const res = await fetch(URL, requestOptions);
  const json = await res.json();
  return json;
};
