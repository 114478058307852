import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

interface Props {
  client_id: string;
  mvp_id: string;
  side: string;
  value?: string;
  isActive: boolean;
  handleSelected: any;
  setHovered: any;
  length?: string;
  height?: string;
}

function MeasureButton({
  client_id,
  mvp_id,
  side,
  value,
  isActive,
  handleSelected,
  setHovered,
  length,
  height,
}: Props) {
  return (
    <Button
      onMouseOver={() => setHovered(mvp_id)}
      onMouseOut={() => setHovered(null)}
      onClick={() => handleSelected(mvp_id)}
      variant={isActive ? "contained" : "outlined"}
      disableRipple
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        textTransform: "none",
      }}
    >
      <Box component="span" sx={{ textAlign: "left" }}>
        Client ID: {client_id}
      </Box>
      <Box component="span" sx={{ textAlign: "left" }}>
        MVP ID: {mvp_id}
      </Box>
      <Box component="span" sx={{ textAlign: "left" }}>
        {value && `Value: ${value.slice(0, 5)}m`}
        {height && `Height: ${height.slice(0, 5)}m`}
      </Box>
      <Box component="span" sx={{ textAlign: "left" }}>
        {length && `Length: ${length.slice(0, 5)}m`}
      </Box>
    </Button>
  );
}

export default MeasureButton;
