import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import timezones from "../../mockData/timezones";

interface Props {
  globalTimezone: any;
  tzInputValue: any;
  setGlobalTimezone: any;
  setTzInputValue: any;
}

function TimezoneDropdown({
  globalTimezone,
  tzInputValue,
  setGlobalTimezone,
  setTzInputValue,
}: Props) {
  return (
    <>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={timezones}
        sx={{ width: 300 }}
        size="small"
        value={globalTimezone || null}
        isOptionEqualToValue={(option, value) => option.label === value.label}
        onChange={(event: any, newValue: any) => {
          setGlobalTimezone(newValue);
        }}
        inputValue={tzInputValue}
        onInputChange={(event, newInputValue) => {
          setTzInputValue(newInputValue);
        }}
        renderInput={(params) => <TextField {...params} label="Timezone" />}
      />
    </>
  );
}

export default TimezoneDropdown;
