import React from "react";
import { Outlet } from "react-router-dom";
import JobDetailsDrawer from "../../Components/JobDetailsDrawer";
const DefaultLayout = () => {
  return (
    <>
      <JobDetailsDrawer />
      <Outlet />
    </>
  );
};

export default DefaultLayout;
