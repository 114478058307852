import React from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CachedIcon from "@mui/icons-material/Cached";

interface Props {
  header: string;
  length: number;
  loading: boolean;
  backgroundLoad: boolean;
  lastUpdate: string;
}

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

function DBCard({
  header,
  length,
  loading,
  backgroundLoad,
  lastUpdate,
}: Props) {
  const getStatus = () => {
    if (backgroundLoad || loading) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Card>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ mb: 2 }}>
            {bull}
            {header}
          </Typography>
          {getStatus() && (
            <CachedIcon
              fontSize="small"
              sx={{
                animation: getStatus() ? "spin 2s linear infinite" : "none",
                "@keyframes spin": {
                  "0%": {
                    transform: "rotate(360deg)",
                  },
                  "100%": {
                    transform: "rotate(0deg)",
                  },
                },
              }}
            />
          )}
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Typography paragraph>Document count: {length}</Typography>
        <Typography paragraph>Loading: {getStatus().toString()}</Typography>
        <Typography paragraph>Last Refresh: {lastUpdate}</Typography>
      </CardContent>
    </Card>
  );
}

export default DBCard;
