import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import { AuthProvider } from "./Context/AuthContext";
import { JobDetailsProvider } from "./Context/JobDetailContext";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme";
import i18n from "./i18n"; // initialized i18next instance
import CssBaseline from "@mui/material/CssBaseline";
import { CookiesProvider } from "react-cookie";
import { LicenseInfo } from "@mui/x-license-pro";

LicenseInfo.setLicenseKey(`${process.env.REACT_APP_MUI_LICENSE}`);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  // <React.StrictMode>
  <Suspense fallback={""}>
    <CssBaseline />
    <CookiesProvider>
      <JobDetailsProvider>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <I18nextProvider i18n={i18n}>
              <App />
            </I18nextProvider>
          </ThemeProvider>
        </AuthProvider>
      </JobDetailsProvider>
    </CookiesProvider>
  </Suspense>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
