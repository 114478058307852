import React, { useState } from "react";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
interface Props {
  header: string;
  children: any;
  downloadPath?: any;
}

function Collapsable({ header, children, downloadPath }: Props) {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const checkForDownloadPath = () => {
    if (!downloadPath) {
      return false;
    }

    if (header.toLowerCase() !== "jobs") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Button
          disableRipple
          onClick={handleClick}
          sx={{ display: "flex", textTransform: "none" }}
        >
          <Typography sx={{ fontWeight: "600" }}>{header}</Typography>
          {open ? <ExpandLess /> : <ExpandMore />}
        </Button>
        {checkForDownloadPath() && (
          <Button
            size="small"
            startIcon={<SaveIcon />}
            component="a"
            download
            target="_blank"
            href={downloadPath}
          ></Button>
        )}
      </Box>
      <Collapse in={open}>{children}</Collapse>
    </>
  );
}

export default Collapsable;
