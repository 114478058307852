import React, { useState, useEffect, SyntheticEvent } from "react";
import { fetchJobs } from "../../Services/Job/Job";
import { useAuth } from "../../Context/AuthContext";
import copy from "../../Helpers/copy";
import getNow from "../../Helpers/getNow";
import DataTable from "./DataTable";
import TabPanel from "./TabPanel";
import Global from "./Global";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Button from "@mui/material/Button";
import LogoutIcon from "@mui/icons-material/Logout";
import CircularProgress from "@mui/material/CircularProgress";

interface FetchJobDataProps {
  database:
    | "sigvaris-qa-db"
    | "sigvaris-prod"
    | "cartier-test"
    | "cartierprodwest";
  stateFunction: any;
  state: any;
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function Dashboard() {
  let { logout, currentUser } = useAuth();

  const [value, setValue] = useState(0);
  const [sigvarisqadb, setSigvarisqadb] = useState<any>({
    loading: false,
    backgroundLoad: false,
    lastUpdate: getNow(),
    data: [],
  });
  const [sigvarisProd, setSigvarisProd] = useState<any>({
    loading: false,
    backgroundLoad: false,
    lastUpdate: getNow(),
    data: [],
  });
  const [cartierTest, setCartierTest] = useState<any>({
    loading: false,
    backgroundLoad: false,
    lastUpdate: getNow(),
    data: [],
  });
  const [cartierProdWest, setCartierProdWest] = useState<any>({
    loading: false,
    backgroundLoad: false,
    lastUpdate: getNow(),
    data: [],
  });

  const dbArray = [
    {
      db: "sigvaris-qa-db",
      state: sigvarisqadb,
      stateFunction: setSigvarisqadb,
    },
    {
      db: "sigvaris-prod",
      state: sigvarisProd,
      stateFunction: setSigvarisProd,
    },
    {
      db: "cartier-test",
      state: cartierTest,
      stateFunction: setCartierTest,
    },
    {
      db: "cartierprodwest",
      state: cartierProdWest,
      stateFunction: setCartierProdWest,
    },
  ];

  const setJobData = async ({
    database,
    stateFunction,
    state,
  }: FetchJobDataProps) => {
    let state_copy = copy(state);
    if (state_copy.data.length > 0) {
      state_copy.backgroundLoad = true;
    } else {
      state_copy.loading = true;
    }
    stateFunction(state_copy);
    let token = await currentUser.getIdToken();

    await fetchJobs({ database: database, token: token }).then((res: any) => {
      stateFunction({
        loading: false,
        backgroundLoad: false,
        lastUpdate: getNow(),
        data: res,
      });
    });
  };

  const fetchAllJobData = async () => {
    dbArray.forEach((i: any) => {
      setJobData({
        database: i.db,
        state: i.state,
        stateFunction: i.stateFunction,
      });
    });
  };

  const getJob = async (
    database:
      | "sigvaris-qa-db"
      | "sigvaris-prod"
      | "cartier-test"
      | "cartierprodwest"
  ) => {
    let dbData: any = null;
    dbArray.forEach((i: any) => {
      if (i.db === database) {
        dbData = i;
      }
    });

    let stateCopy = copy(dbData.state);
    if (stateCopy.data.length > 0) {
      stateCopy.backgroundLoad = true;
    } else {
      stateCopy.loading = true;
    }

    dbData.stateFunction(stateCopy);
    let token = await currentUser.getIdToken();

    await fetchJobs({ database: database, token: token }).then((res: any) => {
      dbData.stateFunction({
        loading: false,
        backgroundLoad: false,
        lastUpdate: getNow(),
        data: res,
      });
    });
  };

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetchAllJobData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLoadingStatus = (db: any) => {
    if (db.loading || db.backgroundLoad) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ borderRight: 1, borderColor: "divider" }}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
          >
            {dbArray.map((i: any, index: number) => {
              return (
                <Tab
                  key={index}
                  label={i.db}
                  {...a11yProps(index)}
                  sx={{ fontSize: "12px", minHeight: "60px" }}
                  icon={
                    getLoadingStatus(i.state) ? (
                      <CircularProgress size={"12px"} />
                    ) : (
                      <Box component="span" sx={{ width: "12px" }}></Box>
                    )
                  }
                  iconPosition="end"
                />
              );
            })}

            <Tab
              key={dbArray.length + 1}
              label="Global"
              sx={{ fontSize: "12px", minHeight: "60px" }}
            />
          </Tabs>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
            <Button
              variant="outlined"
              startIcon={<LogoutIcon />}
              onClick={() => logout()}
            >
              Log out
            </Button>
          </Box>
        </Box>
        <Box sx={{ flex: 1, m: "16px 54px 16px 16px" }}>
          <TabPanel value={value} index={0}>
            <DataTable
              jobs={sigvarisqadb.data}
              loading={sigvarisqadb.loading}
              backgroundLoad={sigvarisqadb.backgroundLoad}
              database="sigvaris-qa-db"
              getJob={getJob}
              lastUpdate={sigvarisqadb.lastUpdate}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <DataTable
              jobs={sigvarisProd.data}
              loading={sigvarisProd.loading}
              backgroundLoad={sigvarisProd.backgroundLoad}
              database="sigvaris-prod"
              getJob={getJob}
              lastUpdate={sigvarisProd.lastUpdate}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <DataTable
              jobs={cartierTest.data}
              loading={cartierTest.loading}
              backgroundLoad={cartierTest.backgroundLoad}
              database="cartier-test"
              getJob={getJob}
              lastUpdate={cartierTest.lastUpdate}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <DataTable
              jobs={cartierProdWest.data}
              loading={cartierProdWest.loading}
              backgroundLoad={cartierProdWest.backgroundLoad}
              database="cartierprodwest"
              getJob={getJob}
              lastUpdate={cartierProdWest.lastUpdate}
            />
          </TabPanel>
          <TabPanel value={value} index={dbArray.length}>
            <Global dbArray={dbArray} />
          </TabPanel>
        </Box>
      </Box>
    </>
  );
}

export default Dashboard;
