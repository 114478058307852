import React, { useState } from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";

interface Props {
  data: any;
}

function NestedDataPopover({ data }: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "details-popover" : undefined;

  return (
    <>
      <IconButton onClick={handleClick} disabled={!Boolean(data)}>
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiPaper-root": {
            background: "rgb(30, 30, 30)",
          },
        }}
      >
        <Box component="pre" sx={{ background: "rgb(30, 30, 30)", px: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleClose} color="primary">
              <CloseIcon />
            </IconButton>
          </Box>
          <Box component="code" sx={{ color: "white" }}>
            <Typography sx={{ whiteSpace: "pre-wrap" }}>
              {JSON.stringify(data, null, 2)}
            </Typography>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export default NestedDataPopover;
