const emptyColumns: any = [
  {
    database: "sigvaris-qa-db",
    columns: [
      "extra_files",
      "notes",
      "weariness",
      "measurer",
      "measurerValidation",
      "ankleCircumferenceR",
      "ankleCircumferenceL",
      "calfCircumferenceR",
      "calfCircumferenceL",
      "calfLengthL",
      "calfLengthR",
      "ankleLengthR",
      "ankleLengthL",
      "thighCircumferenceR",
      "thighCircumferenceL",
      "legLengthR",
      "legLengthL",
      "hipCircumference",
      "appVersion",
      "app_version",
    ],
  },
  {
    database: "sigvaris-prod",
    columns: [
      "extra_files",
      "notes",
      "weariness",
      "measurer",
      "measurerValidation",
      "ankleCircumferenceR",
      "ankleCircumferenceL",
      "calfCircumferenceR",
      "calfCircumferenceL",
      "calfLengthL",
      "calfLengthR",
      "ankleLengthR",
      "ankleLengthL",
      "thighCircumferenceR",
      "thighCircumferenceL",
      "legLengthR",
      "legLengthL",
      "hipCircumference",
      "appVersion",
      "app_version",
    ],
  },
  {
    database: "cartier-test",
    columns: [
      "extra_files",
      "weariness",
      "iOS_tocken",
      "shoe_size",
      "fitter_name",
      "submit_date",
      "pos_email",
      "measurer",
      "measurerValidation",
      "smoothness_score",
      "smoothness_result",
      "ankleCircumferenceR",
      "ankleCircumferenceL",
      "calfCircumferenceR",
      "calfCircumferenceL",
      "calfLengthL",
      "calfLengthR",
      "ankleLengthR",
      "ankleLengthL",
      "thighCircumferenceR",
      "thighCircumferenceL",
      "legLengthR",
      "legLengthL",
      "hipCircumference",
      "notes",
      "app_version",
      "sigvaris_id",
    ],
  },
  {
    database: "cartierprodwest",
    columns: [
      "extra_files",
      "weariness",
      "iOS_tocken",
      "shoe_size",
      "fitter_name",
      "submit_date",
      "pos_email",
      "measurer",
      "measurerValidation",
      "smoothness_score",
      "smoothness_result",
      "ankleCircumferenceR",
      "ankleCircumferenceL",
      "calfCircumferenceR",
      "calfCircumferenceL",
      "calfLengthL",
      "calfLengthR",
      "ankleLengthR",
      "ankleLengthL",
      "thighCircumferenceR",
      "thighCircumferenceL",
      "legLengthR",
      "legLengthL",
      "hipCircumference",
      "notes",
      "app_version",
      "sigvaris_id",
    ],
  },
];

export default emptyColumns;
