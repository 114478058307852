const asciiText = `
 __   __   __  __     ______     ______               
/\\ \\ / /  /\\ \\_\\ \\   /\\  __ \\   /\\  __ \\              
\\ \\ \\'/   \\ \\____ \\  \\ \\ \\/\\ \\  \\ \\ \\/\\ \\             
 \\ \\__|    \\/\\_____\\  \\ \\_____\\  \\ \\_____\\            
  \\/_/      \\/_____/   \\/_____/   \\/_____/            
                                                      
 _____     ______     ______     __  __               
/\\  __-.  /\\  __ \\   /\\  ___\\   /\\ \\_\\ \\              
\\ \\ \\/\\ \\ \\ \\  __ \\  \\ \\___  \\  \\ \\  __ \\             
 \\ \\____-  \\ \\_\\ \\_\\  \\/\\_____\\  \\ \\_\\ \\_\\            
  \\/____/   \\/_/\\/_/   \\/_____/   \\/_/\\/_/            
                                                      
 ______     ______     ______     ______     _____    
/\\  == \\   /\\  __ \\   /\\  __ \\   /\\  == \\   /\\  __-.  
\\ \\  __<   \\ \\ \\/\\ \\  \\ \\  __ \\  \\ \\  __<   \\ \\ \\/\\ \\ 
 \\ \\_____\\  \\ \\_____\\  \\ \\_\\ \\_\\  \\ \\_\\ \\_\\  \\ \\____- 
  \\/_____/   \\/_____/   \\/_/\\/_/   \\/_/ /_/   \\/____/ 
 `;

const releaseText = `Release version: ${process.env.REACT_APP_DEPLOYMENT_VERSION}`;

const asciiStyles = "color:#5C76AA; font-size: 8px;";
const releaseStyles =
  "color: white; background: #5C76AA; padding: 16px; border-radius: 8px;";

export const infolog = () => {
  console.log(`%c${asciiText}`, asciiStyles);
  console.log(`%c${releaseText}`, releaseStyles);
};
