import React, { useEffect, useState } from "react";
import { getMeasurements } from "../../../Services/Job/Job";
import sortMeasures from "../../../Helpers/sortMeasures";
import getHiddenIds from "../../../Helpers/getHiddenIds";
import copy from "../../../Helpers/copy";
import { svgIDsF, svgIDsM } from "../../../mockData/svgIDs";

import BodySVG from "./BodySVG";
import MeasureButton from "./MeasureButton";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import FemaleIcon from "@mui/icons-material/Female";
import MaleIcon from "@mui/icons-material/Male";
import CloseIcon from "@mui/icons-material/Close";
import styled from "@emotion/styled";

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function ErrorText() {
  return (
    <Box>
      <Typography>No measure data found</Typography>
    </Box>
  );
}

interface Props {
  job: any;
  open: boolean;
  handleClose: any;
  database: string;
}

function MeasureModal({ job, open, handleClose, database }: Props) {
  const [measureData, setMeasureData] = useState<any>(null);
  const [hovered, setHovered] = useState<any>("");
  const [selected, setSelected] = useState<any>([]);
  const [gender, setGender] = useState<string>("f");
  const [mainView, setMainView] = useState<string>("FFront");
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [error, setError] = useState<boolean>(false);

  const handleMeasurements = async () => {
    let id = job.id;

    await getMeasurements(id, `Jobs/${id}/outputs/`, database)
      .then((res: any) => {
        setMeasureData(sortMeasures(res));
      })
      .catch((err: any) => {
        setError(true);
      });
  };

  const handleSelected = (id: any) => {
    let stateCopy = copy(selected);

    if (stateCopy.includes(id)) {
      setSelected(stateCopy.filter((i: any) => i !== id));
    } else {
      const returnData = [...stateCopy, id];
      setSelected(returnData);
    }
  };

  const getSelectedIds = (selected: any) => {
    let returnString = "";

    selected.forEach((i: any) => {
      returnString += `#${i}, `;
    });

    return returnString.slice(0, -2);
  };

  const handleGender = (
    event: React.MouseEvent<HTMLElement>,
    gender: string
  ) => {
    let currentView = `${gender.toUpperCase()}${mainView.substring(1)}`;

    setGender(gender);
    setMainView(currentView);
  };

  const handleTabs = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    open && handleMeasurements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      fullWidth
      maxWidth={false}
      open={open}
      onClose={() => handleClose()}
    >
      <DialogContent>
        <Grid2 container sx={{ display: "flex" }} spacing={2}>
          <Grid2 xs={7}>
            <ToggleButtonGroup value={gender} exclusive onChange={handleGender}>
              <ToggleButton value="f">
                <FemaleIcon />
              </ToggleButton>
              <ToggleButton value="m">
                <MaleIcon />
              </ToggleButton>
            </ToggleButtonGroup>
            <HoverContainer
              id={hovered}
              isHidden={measureData && getHiddenIds(measureData)}
              isSelected={getSelectedIds(selected)}
            >
              <Grid2
                container
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Grid2 xs={10} sx={{ maxHeight: "calc(100vh - 200px)" }}>
                  <BodySVG id={mainView} />
                </Grid2>
                <Grid2 xs={12} sx={{ display: "flex" }}>
                  {gender === "f"
                    ? svgIDsF.map((i: any, index: number) => {
                        return (
                          i !== mainView && (
                            <Grid2
                              key={index}
                              xs={4}
                              onClick={() => setMainView(i)}
                              sx={{
                                border: "1px solid #5C76AA",
                                borderRadius: 4,
                                mx: 1,
                                p: 1,
                                cursor: "nwse-resize",
                              }}
                            >
                              <BodySVG id={i} />
                            </Grid2>
                          )
                        );
                      })
                    : svgIDsM.map((i: any, index: number) => {
                        return (
                          i !== mainView && (
                            <Grid2
                              key={index}
                              xs={4}
                              onClick={() => setMainView(i)}
                              sx={{
                                border: "1px solid #5C76AA",
                                borderRadius: 4,
                                mx: 1,
                                p: 1,
                                cursor: "nwse-resize",
                              }}
                            >
                              <BodySVG id={i} />
                            </Grid2>
                          )
                        );
                      })}
                </Grid2>
              </Grid2>
            </HoverContainer>
          </Grid2>
          <Grid2
            xs={5}
            sx={{ display: "flex", gap: 2, flexDirection: "column" }}
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={currentTab} onChange={handleTabs} aria-label="">
                <Tab label="Left" />
                <Tab label="Right" />
                <Tab label="Other" />
              </Tabs>
            </Box>
            <TabPanel value={currentTab} index={0}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: 1,
                }}
              >
                {error && <ErrorText />}
                {measureData &&
                  measureData.map((i: any, index: number) => {
                    return (
                      i.side === "L" && (
                        <MeasureButton
                          key={index}
                          client_id={i.client_id}
                          mvp_id={i.mvp_id}
                          side={i.side}
                          value={i.value}
                          isActive={Boolean(selected.includes(i.mvp_id))}
                          handleSelected={handleSelected}
                          setHovered={setHovered}
                        />
                      )
                    );
                  })}
              </Box>
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: 1,
                }}
              >
                {error && <ErrorText />}
                {measureData &&
                  measureData.map((i: any, index: number) => {
                    return (
                      i.side === "R" && (
                        <MeasureButton
                          key={index}
                          client_id={i.client_id}
                          mvp_id={i.mvp_id}
                          side={i.side}
                          value={i.value}
                          isActive={Boolean(selected.includes(i.mvp_id))}
                          handleSelected={handleSelected}
                          setHovered={setHovered}
                        />
                      )
                    );
                  })}
              </Box>
            </TabPanel>
            <TabPanel value={currentTab} index={2}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: 1,
                }}
              >
                {error && <ErrorText />}
                {measureData &&
                  measureData.map((i: any, index: number) => {
                    return (
                      (i.side === "F" || i.side === "B") && (
                        <MeasureButton
                          key={index}
                          client_id={i.client_id}
                          mvp_id={i.mvp_id}
                          side={i.side}
                          // value={i.cir}
                          length={i.cir}
                          height={i.height}
                          isActive={Boolean(selected.includes(i.mvp_id))}
                          handleSelected={handleSelected}
                          setHovered={setHovered}
                        />
                      )
                    );
                  })}
              </Box>
            </TabPanel>
          </Grid2>
        </Grid2>
        <IconButton
          onClick={() => handleClose()}
          sx={{ position: "absolute", top: "16px", right: "16px" }}
        >
          <CloseIcon />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
}

interface HoverProps {
  id: string;
  children?: any;
  isHidden: string;
  isSelected: string;
}

const HoverContainer = ({ id, children, isHidden, isSelected }: HoverProps) => {
  return (
    <HoverDiv id={id} isHidden={isHidden} isSelected={isSelected}>
      {children}
    </HoverDiv>
  );
};

const HoverDiv = styled.div<HoverProps>`
  display: flex;

  ${(props) => props.isSelected} {
    g {
      circle,
      ellipse,
      path {
        fill: #695caa;
        stroke: #695caa;
        opacity: 0.55;
      }
    }
    path {
      fill: #695caa;
      stroke: #695caa;
      opacity: 0.55;
    }
    circle {
      fill: #695caa;
      opacity: 0.55;
    }
  }

  ${(props) => `#${props.id}`} {
    g {
      circle,
      ellipse,
      path {
        fill: #695caa;
        stroke: #695caa;
        opacity: 0.55;
      }
    }
    path {
      fill: #695caa;
      stroke: #695caa;
      opacity: 0.55;
    }
    circle {
      fill: #695caa;
      opacity: 0.55;
    }
  }

  ${(props) => `${props.isHidden}`} {
    display: none;
  }
`;
export default MeasureModal;
