const header = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": `${process.env.REACT_APP_SERVER_URL}`,
};

export const verifyLogin = async (email: string) => {
  const requestOptions = {
    method: "POST",
    headers: {
      ...header,
    },
    body: JSON.stringify({ email: email }),
  };

  const URL = `${process.env.REACT_APP_SERVER_URL}/auth/verifylogin`;
  const res = await fetch(URL, requestOptions);
  return res.status;
};
