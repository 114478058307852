import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import RequireAuth from "./Helpers/RequireAuth";

import AuthLayout from "./Layouts/AuthLayout";
import DefaultLayout from "./Layouts/DefaultLayout";
// import Dashboard from "./Pages/Dashboard";
import SignIn from "./Pages/SignIn";
// import Sandbox from "./Pages/Sandbox";

import Dashboard from "./Pages/Dashboard";

import { infolog } from "./Helpers/consoleinfo";

infolog();

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<AuthLayout />}>
          <Route path="/" element={<SignIn />} />
          <Route
            path="/*"
            element={
              <RequireAuth redirectTo="/">
                <SignIn />
              </RequireAuth>
            }
          />
        </Route>
        <Route element={<DefaultLayout />}>
          {/* <Route path="/sandbox" element={<Sandbox />} /> */}
          <Route
            path="/dashboard"
            element={
              <RequireAuth redirectTo="/">
                <Dashboard />
              </RequireAuth>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
