import React, { useState, useEffect } from "react";
import { useJobDetails } from "../../../Context/JobDetailContext";
import {
  DataGridPro as MUIDataGrid,
  GridActionsCellItem,
  GridPagination,
} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ArrowUpIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownIcon from "@mui/icons-material/ArrowDownward";
import Tooltip from "@mui/material/Tooltip";
import GridToolbar from "./GridToolbar";
import NestedDataPopover from "./NestedDataPopover";
import { flattenObject } from "../../../Helpers/flattenObj";
import copy from "../../../Helpers/copy";
import formatDate from "../../../Helpers/formatDate";
import emptyColumns from "../../../mockData/emptyColumns";

interface Props {
  jobs: any;
  loading: boolean;
  backgroundLoad: boolean;
  database: string;
  getJob: any;
  lastUpdate: any;
}

function DataTable({
  jobs,
  loading,
  backgroundLoad,
  database,
  getJob,
  lastUpdate,
}: Props) {
  const { addJobDetails, jobDetails } = useJobDetails();
  const [rows, setRows] = useState<any>([]);
  const [columns, setColumns] = useState<any>([]);
  const [timeZone, setTimezone] = useState<any>(null);
  const [globalTimezone, setGlobalTimezone] = useState<any>({
    label: "Europe/London (GMT+00:00)",
    tzCode: "Europe/London",
    name: "(GMT+00:00) London, Birmingham, Liverpool, Sheffield, Bristol",
    utc: "+00:00",
  });
  const [tzInputValue, setTzInputValue] = useState<any>("");
  const [columnVisibilityModel, setColumnVisibilityModel] = useState<any>({});
  const [pinnedRows, setPinnedRows] = React.useState<{
    top: any;
    bottom: any;
  }>({
    top: [],
    bottom: [],
  });

  const handleJobDetails = ({ job }: any) => {
    if (jobDetails.find((item: any) => item["id"] === job.id)) {
      return;
    } else {
      job.database = database;
      addJobDetails({ job: job });
    }
  };

  const AddJobButton = ({ job }: any) => {
    return (
      <>
        <IconButton onClick={() => handleJobDetails({ job: job.row })}>
          <AddCircleOutlineIcon />
        </IconButton>
      </>
    );
  };

  useEffect(() => {
    if (!jobs) {
      return;
    }
    let dataCopy = copy(jobs);
    dataCopy.length &&
      dataCopy?.map((i: any) => {
        return flattenObject(i);
      });

    setRows(dataCopy);
  }, [jobs]);

  const localizeDate = (date: string) => {
    if (!globalTimezone) {
      return date;
    } else {
      return formatDate({ date: date, timeZone: globalTimezone.tzCode });
    }
  };

  useEffect(() => {
    if (!rows) {
      return;
    }
    let uniqueKeys = Object.keys(
      rows.reduce(function (result: any, obj: any) {
        return Object.assign(result, obj);
      }, {})
    );

    let keys_to_filter: any = null;

    emptyColumns.forEach((i: any) => {
      if (i.database === database) {
        keys_to_filter = i.columns;
      }
    });

    let filteredKeys = uniqueKeys.filter(
      (item: any) => !keys_to_filter.includes(item)
    );

    let columnData = filteredKeys.map((i: any) => {
      if (
        i === "timings" ||
        i === "commits" ||
        i === "feedback" ||
        i === "full_traceback"
      ) {
        return {
          field: i,
          headerName: i,
          sortable: false,
          filterable: false,
          align: "center",
          width: 76,
          renderCell: (params: any, index: number) => {
            return <NestedDataPopover key={index} data={params.value} />;
          },
        };
      } else if (i === "_id") {
        return {
          field: i,
          width: 210,
        };
      } else if (i === "init_date" || i === "capture_date_iso") {
        return {
          field: i,
          renderCell: (params: any, index: number) => {
            return <>{localizeDate(params.value)}</>;
          },
        };
      } else {
        return {
          field: i,
        };
      }
    });

    let details = {
      field: "details",
      headerName: "Details",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      width: 60,
      renderCell: (params: any, index: number) => {
        return <AddJobButton key={index} job={params} />;
      },
    };

    let actions = {
      field: "actions",
      type: "actions",
      width: 100,
      getActions: (params: any) => {
        const isPinnedTop =
          pinnedRows.top.filter((item: any) => item?.id === params?.row?.id)
            .length > 0;
        const isPinnedBottom =
          pinnedRows.bottom.filter((item: any) => item?.id === params?.row?.id)
            .length > 0;

        if (isPinnedTop || isPinnedBottom) {
          return [
            <GridActionsCellItem
              label="Unpin"
              icon={
                <Tooltip title="Unpin">
                  {isPinnedTop ? <ArrowDownIcon /> : <ArrowUpIcon />}
                </Tooltip>
              }
              onClick={() =>
                setPinnedRows((prevPinnedRows) => ({
                  top: prevPinnedRows.top.filter(
                    (rowId: any) => rowId !== params.row
                  ),
                  bottom: prevPinnedRows.bottom.filter(
                    (rowId: any) => rowId !== params.row
                  ),
                }))
              }
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title="Pin at the top">
                <ArrowUpIcon />
              </Tooltip>
            }
            label="Pin at the top"
            onClick={() =>
              setPinnedRows((prevPinnedRowsIds) => ({
                ...prevPinnedRowsIds,
                top: [...prevPinnedRowsIds.top, params.row],
              }))
            }
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title="Pin at the bottom">
                <ArrowDownIcon />
              </Tooltip>
            }
            label="Pin at the bottom"
            onClick={() =>
              setPinnedRows((prevPinnedRows) => ({
                ...prevPinnedRows,
                bottom: [...prevPinnedRows.bottom, params.row],
              }))
            }
          />,
        ];
      },
    };

    setColumns([actions, details, ...columnData]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, jobDetails, globalTimezone, pinnedRows]);

  const handleFeedbackExcelURL = () => {
    let visibilityCopy = copy(columnVisibilityModel);
    let visibility: string = "";

    Object.keys(visibilityCopy).forEach((i: any) => {
      if (!visibilityCopy[i]) {
        visibility += `${i},`;
      }
    });
    return `${process.env.REACT_APP_SERVER_URL}/jobs/feedbackexcel?database=${database}&visibility=${visibility}`;
  };

  const feedbackExcelCheck = () => {
    if (database === "sigvaris-qa-db" || database === "sigvaris-prod") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Box
      sx={{
        height: "calc(100vh - 40px)",
        minHeight: "600px",
        width: "calc(100vw - 254px)",
      }}
    >
      <MUIDataGrid
        rows={rows}
        columns={columns}
        loading={loading}
        pinnedRows={pinnedRows}
        unstable_headerFilters
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        pageSizeOptions={[50, 100, 200]}
        pagination={true}
        slots={{
          toolbar: GridToolbar,
          loadingOverlay: LinearProgress,
          footer: () => (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mx: 2,
                }}
              >
                <Typography variant="body2">
                  Last update: {localizeDate(lastUpdate)}
                </Typography>
                <GridPagination />
              </Box>
            </>
          ),
        }}
        slotProps={{
          toolbar: {
            getJob: getJob,
            database: database,
            setTimezone: setTimezone,
            timeZone: timeZone,
            globalTimezone: globalTimezone,
            setGlobalTimezone: setGlobalTimezone,
            tzInputValue: tzInputValue,
            setTzInputValue: setTzInputValue,
            handleFeedbackExcelURL: handleFeedbackExcelURL,
            feedbackExport: feedbackExcelCheck(),
            backgroundLoad: backgroundLoad,
          },
        }}
      />
    </Box>
  );
}

export default DataTable;
