const idMaps = [
  {
    client_id: "h",
    mvp_id: "H",
    type: "height",
  },
  {
    client_id: "cH",
    mvp_id: "HEc",
    type: "circumference",
  },
  {
    client_id: "cN",
    mvp_id: "Nc",
    type: "circumference",
  },
  {
    client_id: "cCH",
    mvp_id: "CHc",
    type: "circumference",
  },
  {
    client_id: "wS",
    mvp_id: "Sw",
    type: "",
  },
  {
    client_id: "wBA",
    mvp_id: "Bw",
    type: "",
  },
  {
    client_id: "lBA1",
    mvp_id: "Bl1",
    type: "height",
  },
  {
    client_id: "lBA2",
    mvp_id: "Bl2",
    type: "height",
  },
  {
    client_id: "lSL",
    mvp_id: "Sl",
    type: "height",
  },
  {
    client_id: "cHA",
    mvp_id: "Hac",
    type: "circumference",
  },
  {
    client_id: "cHAC",
    mvp_id: "Hac2",
    type: "circumference",
  },
  {
    client_id: "lHA",
    mvp_id: "Hal",
    type: "height",
  },
  {
    client_id: "cF1",
    mvp_id: "Fc5",
    type: "circumference",
  },
  {
    client_id: "cF2",
    mvp_id: "Fc1",
    type: "circumference",
  },
  {
    client_id: "cF3",
    mvp_id: "Fc2",
    type: "circumference",
  },
  {
    client_id: "cF4",
    mvp_id: "Fc3",
    type: "circumference",
  },
  {
    client_id: "cF5",
    mvp_id: "Fc4",
    type: "circumference",
  },
  {
    client_id: "cW1",
    mvp_id: "Wc1",
    type: "circumference",
  },
  {
    client_id: "cW2",
    mvp_id: "Wc2",
    type: "circumference",
  },
  {
    client_id: "cW3",
    mvp_id: "Wc3",
    type: "circumference",
  },
  {
    client_id: "cWA",
    mvp_id: "W1",
    type: "circumference",
  },
  {
    client_id: "cT",
    mvp_id: "W2",
    type: "circumference",
  },
  {
    client_id: "cAB",
    mvp_id: "Ac",
    type: "circumference",
  },
  {
    client_id: "lAB",
    mvp_id: "WAl",
    type: "height",
  },
  {
    client_id: "lHI",
    mvp_id: "WHl",
    type: "height",
  },
  {
    client_id: "lW",
    mvp_id: "WCl",
    type: "height",
  },
  {
    client_id: "tCR",
    mvp_id: "Cl",
    type: "height",
  },
  {
    client_id: "wCR",
    mvp_id: "Cw",
    type: "height",
  },
  {
    client_id: "CRf",
    mvp_id: "fbCl",
    type: "height",
  },
  {
    client_id: "CRb",
    mvp_id: "fbCl",
    type: "height",
  },
  {
    client_id: "dCR",
    mvp_id: "Cdl",
    type: "height",
  },
  {
    client_id: "hL1",
    mvp_id: "Ol",
    type: "height",
  },
  {
    client_id: "lT",
    mvp_id: "HWl1",
    type: "height",
  },
  {
    client_id: "lL1",
    mvp_id: "Il",
    type: "height",
  },
  {
    client_id: "cA",
    mvp_id: "Mc",
    type: "circumference",
  },
  {
    client_id: "cY",
    mvp_id: "HIc",
    type: "circumference",
  },
  {
    client_id: "cB",
    mvp_id: "Ac",
    type: "circumference",
  },
  {
    client_id: "cB1",
    mvp_id: "uCc",
    type: "circumference",
  },
  {
    client_id: "cC",
    mvp_id: "Cc",
    type: "circumference",
  },
  {
    client_id: "cD",
    mvp_id: "Fc",
    type: "circumference",
  },
  {
    client_id: "cF",
    mvp_id: "mTc",
    type: "circumference",
  },
  {
    client_id: "cG",
    mvp_id: "uTc",
    type: "circumference",
  },
  {
    client_id: "cHi",
    mvp_id: "Hc",
    type: "circumference",
  },
  {
    client_id: "lA",
    mvp_id: "MHl",
    type: "height",
  },
  {
    client_id: "lZ",
    mvp_id: "THl",
    type: "height",
  },
  {
    client_id: "lB",
    mvp_id: "HAl",
    type: "height",
  },
  {
    client_id: "lB1",
    mvp_id: "HCl",
    type: "height",
  },
  {
    client_id: "lC",
    mvp_id: "HCl2",
    type: "height",
  },
  {
    client_id: "lD",
    mvp_id: "HFl",
    type: "height",
  },
  {
    client_id: "lE",
    mvp_id: "HKl",
    type: "height",
  },
  {
    client_id: "lF",
    mvp_id: "HTl1",
    type: "height",
  },
  {
    client_id: "lG",
    mvp_id: "HTl2",
    type: "height",
  },
  {
    client_id: "lK",
    mvp_id: "CKl",
    type: "height",
  },
  {
    client_id: "cE",
    mvp_id: "Kc",
    type: "circumference",
  },
  {
    client_id: "lKF",
    mvp_id: "WKl",
    type: "height",
  },
  {
    client_id: "lKB",
    mvp_id: "KWl",
    type: "height",
  },
  {
    client_id: "hF",
    mvp_id: "HWl2",
    type: "height",
  },
  {
    client_id: "wF",
    mvp_id: "Fw",
    type: "height",
  },
  {
    client_id: "lC",
    mvp_id: "CCl",
    type: "height",
  },
  {
    client_id: "hB",
    mvp_id: "WIl",
    type: "height",
  },
  {
    client_id: "lA",
    mvp_id: "CAl",
    type: "height",
  },
  {
    client_id: "F_lK-T",
    mvp_id: "CWl",
    type: "height",
  },
  {
    client_id: "B_lK-T",
    mvp_id: "BCl",
    type: "height",
  },
  {
    client_id: "lH",
    mvp_id: "FGl",
    type: "height",
  },
  {
    client_id: "ankleCircum",
    mvp_id: "Ac",
    type: "circumference",
  },
  {
    client_id: "calfCircum",
    mvp_id: "Cc",
    type: "circumference",
  },
  {
    client_id: "thighCircum",
    mvp_id: "uTc",
    type: "circumference",
  },
  {
    client_id: "calfLenght",
    mvp_id: "HFl",
    type: "height",
  },
  {
    client_id: "hipCircum",
    mvp_id: "Hc",
    type: "circumference",
  },
];

export default idMaps;
