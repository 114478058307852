import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";

interface RequireAuthProps {
  children: any;
  redirectTo: string;
  isAdmin?: boolean;
}

// @TODO:
// Possibility to rescrict access based on role here by getting 'role' from useAuth();

function RequireAuth({ children, redirectTo, isAdmin }: RequireAuthProps) {
  let { currentUser, admin } = useAuth();
  if (isAdmin && !admin) {
    return <Navigate to={redirectTo} />;
  } else {
    return currentUser ? children : <Navigate to={redirectTo} />;
  }
}

export default RequireAuth;
