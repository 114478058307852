import React from "react";
import { Outlet } from "react-router-dom";
import Grid from "@mui/material/Grid";

const AuthLayout = () => {
  return (
    <Grid
      container
      sx={{
        display: "flex",
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
        // minWidth: "100vw",
        p: 4,
        background:
          "linear-gradient(90deg, rgba(96,105,168,1) 0%, rgba(93,131,177,1) 25%, rgba(83,165,183,1) 50%, rgba(78,176,183,1) 75%, rgba(74,186,182,1) 100%)    ",
      }}
    >
      <Outlet />
    </Grid>
  );
};

export default AuthLayout;
