// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

let tenant_id = process.env.REACT_APP_TENANT_ID;

const firebaseConfig = {
  apiKey: "AIzaSyCZx4SyGzdEE9LRPNdWGPOlUvAK1IHTJPs",
  authDomain: "vyoo-dashboard-d8f79.firebaseapp.com",
  projectId: "vyoo-dashboard-d8f79",
  storageBucket: "vyoo-dashboard-d8f79.appspot.com",
  messagingSenderId: "383803506093",
  appId: "1:383803506093:web:1f47ec20a778f1ac473524",
  tenant: tenant_id,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
