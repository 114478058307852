import React, { createContext, useContext, useState } from "react";
const JobDetailContext = createContext<any>(null);

export const useJobDetails = () => useContext(JobDetailContext);

export function JobDetailsProvider({ children }: any) {
  const [jobDetails, setJobDetails] = useState<any>([]);

  const addJobDetails = ({ job }: any) => {
    setJobDetails((prevState: any) => [...prevState, job]);
  };

  const removeJobDetails = ({ job }: any) => {
    // filter the state by like the job id or something
    // remove from state
    let state_copy = [...jobDetails];
    let filter = { id: job?.id, database: job?.database };
    let new_state = state_copy.filter((i: any) => {
      for (let key in filter) {
        if (i[key] === undefined || i[key] !== filter[key]) {
          return true;
        }
        return false;
      }
      return null;
    });
    setJobDetails(new_state);
  };

  const value = {
    jobDetails,
    addJobDetails,
    removeJobDetails,
  };

  return (
    <JobDetailContext.Provider value={value}>
      {children}
    </JobDetailContext.Provider>
  );
}
