import React, { useState, useRef } from "react";
import { useJobDetails } from "../../Context/JobDetailContext";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Fade from "@mui/material/Fade";
import JobDetail from "../JobDetail";

function JobDetailsDrawer() {
  const containerRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const { jobDetails } = useJobDetails();

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      anchor="right"
      variant="permanent"
    >
      <Box
        ref={containerRef}
        sx={{
          position: "relative",
          minWidth: {
            xs: open ? "100vw" : "20px",
            sm: open ? "540px" : "20px",
          },
          width: !open ? "36px" : null,
          transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          "& .MuiDrawer-paper": {
            minWidth: "20px",
          },
        }}
      >
        <Button
          onClick={() => handleDrawerOpen()}
          disableRipple
          fullWidth
          sx={{
            padding: "6px 0",
            justifyContent: "flex-start",
            borderRadius: "0",
            // width: "32px",
            "&:hover": {
              background: "#5C76AA",
              "& svg": {
                path: {
                  color: "white",
                },
              },
            },
          }}
        >
          <ChevronLeftIcon
            sx={{
              width: "32px",
              transform: open ? "rotate(180deg)" : null,
              transition: "all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            }}
          />
        </Button>
        <Fade in={open}>
          <Box sx={{ display: open ? "block" : "none", my: 4, mx: 2 }}>
            {jobDetails.map((i: any, index: number) => {
              return (
                <JobDetail
                  key={i.id}
                  job={i}
                  job_id={i.id}
                  database={i.database}
                />
              );
            })}
          </Box>
        </Fade>
      </Box>
    </Drawer>
  );
}

export default JobDetailsDrawer;
