import React from "react";
import Box from "@mui/material/Box";
import DBCard from "./DBCard";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

interface Props {
  dbArray: any;
}

function Global({ dbArray }: Props) {
  return (
    <Box>
      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={4}>
          <Card>
            <CardContent>
              <Typography sx={{ mb: 2 }}>{bull} Global</Typography>
              <Divider sx={{ mb: 2 }} />
              <Button
                startIcon={<SaveAltIcon />}
                component="a"
                download
                href={`${process.env.REACT_APP_SERVER_URL}/jobs/csv`}
              >
                Export All
              </Button>
            </CardContent>
          </Card>
        </Grid2>
        <Grid2 container xs={12} md={8} spacing={2}>
          {dbArray.map((i: any, index: number) => {
            return (
              <Grid2 key={index} xs={12} md={6}>
                <DBCard
                  header={i.db}
                  length={i.state.data?.length}
                  loading={i.state.loading}
                  backgroundLoad={i.state.backgroundLoad}
                  lastUpdate={i.state?.lastUpdate}
                />
              </Grid2>
            );
          })}
        </Grid2>
      </Grid2>
    </Box>
  );
}

export default Global;
