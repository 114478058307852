import { createTheme } from "@mui/material/styles";

interface PaletteOptions {
  palette: {
    primary: {
      main: string;
    };
  };
}

export const theme: PaletteOptions = createTheme({
  palette: {
    primary: {
      main: "#5C76AA",
    },
  },
});
