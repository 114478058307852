import React, { useEffect } from "react";
import { useAuth } from "../../Context/AuthContext";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AuthCard from "../../Components/AuthCard";

const SignIn = () => {
  let navigate = useNavigate();
  let { currentUser, fbSignIn } = useAuth();

  useEffect(() => {
    if (currentUser) {
      navigate("/dashboard");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  return (
    <>
      <AuthCard>
        <Box sx={{ pt: 4 }} />
        <Button variant="contained" fullWidth onClick={() => fbSignIn()}>
          <Typography sx={{ my: 2 }}>Login</Typography>
        </Button>
      </AuthCard>
    </>
  );
};

export default SignIn;
