import React, { useState, useEffect } from "react";
import { useJobDetails } from "../../Context/JobDetailContext";
import { useAuth } from "../../Context/AuthContext";
import { fetchJob } from "../../Services/Job/Job";
import { findNestedObj, buildFolderStructure } from "../../Helpers/jobDetails";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Collapsable from "../../Components/Collapsable";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import MeasureModal from "./MeasureModal";

function ListItem({ listItem, handleFile, getArchiveUrl }: any) {
  const { name, children, files, path } = listItem;
  let downloadPath = path && getArchiveUrl(path);
  return (
    <Box sx={{ ml: 1 }}>
      <Collapsable header={name} downloadPath={downloadPath}>
        {files && <File item={files} url={path} handleFile={handleFile} />}
        {Array.isArray(children) && (
          <List
            list={children}
            handleFile={handleFile}
            getArchiveUrl={getArchiveUrl}
          />
        )}
      </Collapsable>
    </Box>
  );
}

function File({ item, url, handleFile }: any) {
  return (
    <Box sx={{ ml: 1 }}>
      {item.map((e: any) => {
        return e.map((f: any) => {
          const href = handleFile(url, f);
          return (
            <Typography
              key={f}
              component={"a"}
              href={href}
              download
              target="_blank"
              sx={{
                display: "block",
                color: "primary.main",
                textDecoration: "none",
                "&:hover": {
                  opacity: "0.5",
                  cursor: "pointer",
                },
              }}
            >
              {f}
            </Typography>
          );
        });
      })}
    </Box>
  );
}

function List({ list, handleFile, getArchiveUrl }: any) {
  return (
    <Box sx={{ ml: 1 }}>
      {list.map((listItem: any) => (
        <ListItem
          key={listItem.name ? listItem.name : listItem}
          listItem={listItem}
          handleFile={handleFile}
          getArchiveUrl={getArchiveUrl}
        />
      ))}
    </Box>
  );
}

interface Props {
  job: any;
  job_id: string;
  database: string;
}

function JobDetail({ job, job_id, database }: Props) {
  const { removeJobDetails } = useJobDetails();
  const { currentUser } = useAuth();
  const [data, setData] = useState<any | null>(null);
  const [folders, setFolders] = useState<any>([]);
  const [measureModalOpen, setMeasureModalOpen] = useState<boolean>(false);

  const handleClose = () => {
    setMeasureModalOpen(false);
  };

  const handleOpen = () => {
    setMeasureModalOpen(true);
  };

  const fetchData = async () => {
    let token = await currentUser.getIdToken();
    fetchJob(job_id, database, token).then((res: any) => {
      res.forEach(function (path: any) {
        buildTree(path.split("/"));
        setData(tree);
      });
    });
  };

  const getFileUrl = (path: any, filename: any) => {
    return `${process.env.REACT_APP_SERVER_URL}/jobs/download?job_id=${job_id}&path=${path}&filename=${filename}&database=${database}`;
  };

  const getArchiveUrl = (path: string) => {
    if (!path) {
      return null;
    }
    return `${
      process.env.REACT_APP_SERVER_URL
    }/jobs/archive?database=${database}&path=${path.slice(0, -1)}`;
  };

  let tree = {
    // Represents the "root" directory, like in a filesystem.
    root: {
      absolute_path: "",
      files: [],
    },
  };

  function buildTree(parts: any) {
    let lastDir = "root";
    let abs_path = "";

    parts.forEach(function (name: any) {
      // It's a directory
      if (name.indexOf(".") === -1) {
        lastDir = name;
        abs_path += lastDir + "/";

        if (!tree[name]) {
          tree[name] = {
            absolute_path: abs_path,
            files: [],
          };
        }
      } else {
        if (!tree[lastDir].files.includes(name)) {
          tree[lastDir].files.push(name);
        }
      }
    });
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let folder_init = findNestedObj(data, "absolute_path");
    let folderData = buildFolderStructure(folder_init, data);
    setFolders(folderData);
  }, [data]);

  const getResultsURL = () => {
    if (database === "sigvaris-qa-db") {
      return `${process.env.REACT_APP_SIGVARIS_QA_DB_URL}/assets/downloadexcelfile/${job_id}`;
    } else if (database === "sigvaris-prod") {
      return `${process.env.REACT_APP_SIGVARIS_PROD_URL}/assets/downloadexcelfile/${job_id}`;
    } else {
      return "";
    }
  };

  return (
    <>
      <Paper sx={{ mb: 2 }} elevation={3}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            py: 1,
          }}
        >
          <Box sx={{ ml: 2 }}>
            <Typography variant="caption" sx={{ color: "#757575" }}>
              {database}
            </Typography>
            <Typography>• {job_id}</Typography>
          </Box>
          <Box>
            <IconButton
              onClick={() => {
                removeJobDetails({ job: job });
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <>
          <Divider sx={{ mx: 1 }} />
          <Box sx={{ m: 2, display: "flex" }}>
            {database.includes("sigvaris") && (
              <>
                <Button
                  component="a"
                  href={getResultsURL()}
                  download
                  size="small"
                  target="_blank"
                  startIcon={<SaveAltIcon />}
                >
                  Results Excel
                </Button>
                <MeasureModal
                  job={job}
                  open={measureModalOpen}
                  handleClose={handleClose}
                  database={database}
                />
                <Button
                  size="small"
                  startIcon={<ZoomInIcon />}
                  onClick={() => handleOpen()}
                  disabled={job.mode === 4}
                >
                  Measures
                </Button>
              </>
            )}
          </Box>
        </>

        {folders && (
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Browse files
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <List
                  list={folders}
                  handleFile={getFileUrl}
                  getArchiveUrl={getArchiveUrl}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion
          sx={{
            ".MuiCollapse-root": { transitionDuration: "300ms !important" },
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            Browse data
          </AccordionSummary>
          <AccordionDetails>
            <Box
              component="pre"
              sx={{
                background: "rgb(30, 30, 30)",
                p: 2,
                position: "relative",
                maxWidth: "474px",
                overflowY: "scroll",
              }}
            >
              <IconButton
                sx={{ position: "absolute", top: 2, right: 2 }}
                onClick={() =>
                  navigator.clipboard.writeText(JSON.stringify(job, null, 2))
                }
              >
                <ContentCopyIcon sx={{ color: "white" }} />
              </IconButton>
              <Box component="code" sx={{ color: "white" }}>
                {JSON.stringify(job, null, 2)}
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </>
  );
}

export default JobDetail;
