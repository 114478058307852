import React, { createContext, useContext, useState, useEffect } from "react";
import { signOut, signInWithPopup } from "firebase/auth";
import { auth } from "../firebase";
import provider from "../auth_msft_create_provider";
import { verifyLogin } from "../Services/Auth/Auth";
// To get the current auth level call .getIdTokenResult(); on currentUser

const AuthContext = createContext<any>(null);

export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }: any) {
  const [currentUser, setCurrentUser] = useState<any>(null);

  useEffect(() => {
    if (auth.currentUser) {
      setCurrentUser(auth.currentUser);
    }
  }, []);

  const fbSignIn = async () => {
    return signInWithPopup(auth, provider)
      .then((result) => {
        let email = auth.currentUser?.email;

        verifyLogin(email).then((res: any) => {
          if (res === 200) {
            setCurrentUser(auth.currentUser);
          } else {
            logout();
          }
        });
        // User is signed in.
        // IdP data available in result.additionalUserInfo.profile.

        // Get the OAuth access token and ID Token
        // const credential = OAuthProvider.credentialFromResult(result);
        // const accessToken = credential?.accessToken;
        // const idToken = credential?.idToken;
      })
      .catch((error) => {
        console.log(error);
        // Handle error.
      });
  };

  const logout = () => {
    signOut(auth);
    setCurrentUser(null);
  };

  const value = {
    fbSignIn,
    logout,
    currentUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
