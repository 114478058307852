export const flattenObject = (obj: any) => {
  if (typeof obj !== "object") {
    return obj;
  }

  let flattened: any = {};

  Object.keys(obj).forEach((key, index) => {
    const value = obj[key];

    if (value?.$date) {
      let date = value.$date;
      delete value?.$date;
      obj[key] = date;
    } else if (value?.$oid) {
      let oid = value.$oid;
      delete value?.$oid;
      obj[key] = oid;
    } else if (key === "timings") {
      // do something
    } else if (
      typeof value === "object" &&
      value !== null &&
      !Array.isArray(value)
    ) {
      Object.assign(flattened, flattenObject(value));
    } else {
      flattened[key] = value;
    }
  });

  return flattened;
};
