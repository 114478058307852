export function findNestedObj(entireObj: any, keyToFind: any) {
  let foundObj: any[] = [];
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind]) {
      foundObj.push(nestedValue[keyToFind]);
    }
    return nestedValue;
  });

  return foundObj;
}

export function findFiles(entireObj: any, keyToFind: any) {
  let foundObj: any[] = [];
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue && nestedValue[keyToFind]) {
      foundObj.push(nestedValue);
    }
    return nestedValue;
  });

  return foundObj;
}

export function buildFolderStructure(obj: any, obj2: any) {
  let result: any[] = [];
  let level = { result };
  let data = findFiles(obj2, "files");

  obj.forEach((path: any) => {
    path.split("/").reduce((r: any, name: any, i: any, a: any) => {
      let files: any[] = [];
      data.forEach((i: any) => {
        if (i.absolute_path === path) {
          files.push(i.files);
        }
      });
      if (!r[name] && name !== "") {
        r[name] = { result: [] };
        r.result.push({
          name,
          children: r[name].result,
          files: files,
          path: path,
        });
      }
      return r[name];
    }, level);
  });

  return result;
}
