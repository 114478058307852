import React from "react";
import {
  FFront,
  FFrontC,
  FBack,
  FSide,
  MFront,
  MFrontC,
  MBack,
  MSide,
} from "./SVGs/SVGs";

interface Props {
  id:
    | "FFront"
    | "FFrontC"
    | "FBack"
    | "FSide"
    | "MFront"
    | "MFrontC"
    | "MBack"
    | "MSide"
    | string;
}

const components = {
  FFront: FFront,
  FFrontC: FFrontC,
  FBack: FBack,
  FSide: FSide,
  MFront: MFront,
  MFrontC: MFrontC,
  MBack: MBack,
  MSide: MSide,
};

function BodySVG({ id }: Props) {
  const SpecifiedSVG = components[id];

  return <SpecifiedSVG />;
}

export default BodySVG;
